import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import axios from 'axios';
import { Navbar, Nav, Container, Row, Col, Form, Button } from "react-bootstrap";
import $ from "jquery"
import GenerateLink from "../common/site/generate-link"
// Images
import BrandLogo from "../../images/logo.svg";
import BrandLogoDark from "../../images/logo-dark.svg";
import "./Header.scss";
import MegaMenu from './MegaMenu';
import MenuData from './MenuData';
import Helmet from 'react-helmet'
import { setUtmCookie } from '../common/site/cookie';

import { ABOUT_PAGE_URL, AREA_GUIDE_PAGE_URL, TESTIMONIALS_PAGE_URL, GIVING_BACK_PAGE_URL } from "../common/site/constants"

const Header = (props) => {


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menusHeaders(sort: "Sort:asc") {
        id
        Label
        Link {
          id
        }
        Sort
        Submenu_Search_Title
        In_Submenu_Show_Search
        Search_Type
        Add_Sub_Menus {
          Title
          Add_here {
            Submenu_Label
            Submenu_Link {
              id
            }
          }
        }
      }

    }
  }
`);

  const menus = data.glstrapi.menusHeaders;

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [state, setState] = useState([]);


  const [loginstatus, setLoginStatus] = useState('')
  const getloginstatus = async url => {
   
    try {
      const data = fetch(url, {
        method: "GET",
        //mode: "no-cors",
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          "Content-Type": "application/json",
        },
        credentials: 'include'
      }).then(response => response.text())
        .then((result) => {
          setLoginStatus(result) 
          localStorage.setItem('myaccount-login-status', result)
          if(result == 'true' || result == true){
            wishlistpropertyadd()
          } 
        })
        .catch(error => console.log('error', error));

      // setLoginStatus(data.text())
    } catch (error) {
      console.error(error)
    }

  }

  const wishlistpropertyadd = async () => { 
    let cookieProperty = localStorage.getItem('cookieProperty');
    if(cookieProperty){
      cookieProperty = JSON.parse(cookieProperty); 
      if(cookieProperty.length > 0) {
        console.log('myaccount-login-status result', cookieProperty.length)
        cookieProperty.map((property)=>{
          try {
            fetch(`https://myaccount.wardsofkent.co.uk/api/propertyshortlist/${property.crm_id}`, {
              method: 'PUT',
              //mode: "no-cors",
              redirect: 'follow',
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                "Content-Type": "application/json",
              },
              body: '',
              credentials: 'include'
            }).then(response => response.text())
              .then(result => {
                cookieProperty = cookieProperty.filter((item)=>item.crm_id != property.crm_id)
                localStorage.setItem('cookieProperty', JSON.stringify([...cookieProperty]));
              })
              .catch(error => console.log('error', error)); 
          } catch (error) {
            console.error(error)
          }
        }) 
      }
    }  
  }


  useEffect(() => {
    setUtmCookie();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
    if (menus && menus.length > 0) {
      var mapMenuList = []
      menus.map((item, i) => {
        if (item.Add_Sub_Menus.length > 0) {
          var menu = {
            name: item.Label,
            link: item.Link,
            text: item.Submenu_Search_Title,
            search: item.In_Submenu_Show_Search,
            type: item.Search_Type,
            isActive: false,
            submenu: []
          }
          item.Add_Sub_Menus.map((submenu, i) => {
            submenu.Add_here.map((innermenu, i) => {
              menu.submenu.push({
                name: innermenu.Submenu_Label,
                link: innermenu.Submenu_Link,
                isActive: false,
              })
            })
          })
          mapMenuList.push(menu)
        } else {
          mapMenuList.push({
            name: item.Label,
            link: item.Link,
            isActive: false,
          })
        }
      })
      setState(mapMenuList)
    }

    if (document.getElementById("widget") === null) {
      var script = document.createElement('script');
      script.id = 'widget';
      script.defer = true;
      script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      document.getElementsByTagName('head')[0].appendChild(script);
    }



    getloginstatus('https://myaccount.wardsofkent.co.uk/api/isloggedin')

    // Find other page and remove active filter
    // console.log("$$$$$-------", window.location.pathname)
    if ($(".search-result-page").length) {
      // console.log("$$$$$$$$$$$$$$$-----search page")
    } else {
      // console.log("$$$$$$$$$$$$$$$-----not search page")
      $("body").removeClass("search-template");
      $("body").removeClass("filter-active");
    }
    // Find other page and remove active filter

  }, []);



  function insantclick() {
    const page_url = typeof window !== 'undefined' ? window.location.pathname : ''
    if (page_url === '/sell-your-property/property-valuation') {
      if (typeof window) {
        window.location.reload();
      }
    }
  }


  const showMenu = () => {
    setShow(!show);
    // document.querySelector("body").classList.add("overflow-hidden");

  };
  const handlerOpenMenu = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    let isActive = a[index].isActive;
    a[index].isActive = !isActive;
    setState(a);
  };

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation();
    let newState = [...state];
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    newState[index].submenu = a;
    let isActive = newState[index].submenu[i].isActive;
    newState[index].submenu[i].isActive = !isActive;
    setState(newState);
  };

  const closeMenu = () => {
    setShow(!show);
    document.querySelector("body").classList.remove("overflow-hidden");
    setState(
      state.map(menu => {
        if (Array.isArray(menu.submenu)) {
          let p = menu.submenu.map(sub => ({ ...sub, ...{ isActive: false } }));
          return ({ ...menu, ...{ submenu: p }, ...{ isActive: false } })
        } else {
          return ({ ...menu, ...{ isActive: false } })
        }
      })
    )
  }

  // const valuation = () => {
  //   const page_url = typeof window !== 'undefined' ? window.location.pathname : ''
  //   if(page_url === '/sell-your-property/property-valuation') {
  //     window.location.reload();
  //   }
  // }

  return (
    <>
      <header className={scroll && props.type != "results" ? "header--fixed header" : scroll && props.type == "results" ? "header results-header" : "header"}>
        <Helmet>
          {/* <script src="https://homewise.dabdev.net/js/homewise-calculator-widget.js"></script> */}
          <script defer
            type="text/javascript"
            src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&libraries=places`}
          ></script>

        </Helmet>

        <Container fluid className={props.type == "results" ? "search-results-container" : ""}>
          <Row>
            <Col>
              <Nav>
                <div className="header-wrapper">
                  <div className="logo">
                    <Link to="/">
                      <img src={BrandLogo} alt="logo" width="108" className="light-logo" />
                      <img src={BrandLogoDark} className="dark-logo" alt="logo" width="108" />
                    </Link>
                  </div>


                  <div className="nav-right ml-auto d-flex d-lg-block">
                    {/* Top Nav */}

                    <div className="d-none d-lg-block menu-wrap all-menu-list">
                      <ul>
                        <li>
                          <Link to="/sell-your-property/property-valuation/" onClick={() => insantclick()} className="menu-link menu-link-first">
                            <i className="icon-home icon-home-hover"></i>Instant Valuation
                          </Link>
                        </li>
                        <li>
                          <a href="https://myaccount.wardsofkent.co.uk/" target="_blank" className="menu-link dropdown">
                            <i className="icon-lock"></i>My Account
                            <div class="dropdown-content">
                              <ul>
                                <li>
                                  <a href="https://myaccount.wardsofkent.co.uk/" target="_blank">{loginstatus == "true" ? 'Dashboard' : 'Login'}</a>
                                </li>
                                {loginstatus != "true" &&
                                  <li>
                                    <a href="https://myaccount.wardsofkent.co.uk/account/register/" target="_blank">Register</a>
                                  </li>
                                }
                                {loginstatus == "true" ?
                                  <li>
                                    <a href="https://myaccount.wardsofkent.co.uk/Property" target="_blank">Saved Properties</a>
                                  </li>
                                  :
                                  <li>
                                    <a href="/saved-properties/" target="_blank">Saved Properties</a>
                                  </li>
                                }
                                <li>
                                  <a href="https://thehub.arunestates.co.uk/wardsofkent/Login/" target="_blank">Tenant Login</a>
                                </li>
                                <li>
                                  <a href="https://thehub.arunestates.co.uk/wardsofkent/Login/" target="_blank">Landlord Login</a>
                                </li>
                              </ul>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href={`https://careers.arunestates.co.uk/`} className="menu-link">
                            <i className="icon-user-plus"></i>Careers
                          </a>
                        </li>
                        <li >
                          <Link to={ABOUT_PAGE_URL.alias} className="menu-link dropdown">
                            <i className="icon-about"></i>About
                            <div class="dropdown-content">
                              <ul>
                                <li>
                                  <Link to={ABOUT_PAGE_URL.alias}>About Us</Link>
                                </li>
                                <li>
                                  <Link to={GIVING_BACK_PAGE_URL.alias}>Giving Back</Link>
                                </li>
                                <li>
                                  <Link to={TESTIMONIALS_PAGE_URL.alias}>Testimonials</Link>
                                </li>
                                <li>
                                  <Link to={AREA_GUIDE_PAGE_URL.alias}>Area Guides</Link>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* Main Nav */}
                    <Nav className="d-none d-lg-block main-nav">
                      <ul>

                        {menus &&
                          menus.map((item, i) => (
                            <li className={`main-head ${item.Add_Sub_Menus.length > 0 ? "dropdown-item-link" : ''}`}>
                              <GenerateLink link={item.Link}>{item.Label}</GenerateLink>
                              {item.Add_Sub_Menus &&
                                <MegaMenu data={item} val={i} Search_Type={item.Search_Type} />
                              }
                            </li>
                          )
                          )}
                        <li className="get-started-form fixed-valuation-button">
                          <Link to="/sell-your-property/property-valuation" className="btn btn-secondary">Instant Valuation <i class="icon-arrow head"></i></Link>
                        </li>
                      </ul>
                    </Nav>

                    <div className="header-btn d-lg-none">
                      {/* <button type="button" className="btn-link search-btn"><i className="icon-search"></i></button> */}
                      <button type="button" className="btn-link dropdown">
                        {/* <a href="https://myaccount.wardsofkent.co.uk/" target="_blank" > */}
                        <i className="icon-user"></i>
                        <div class="dropdown-content">
                          <ul>
                            <li>
                              <a href="https://myaccount.wardsofkent.co.uk/" target="_blank">{loginstatus == "true" ? 'Dashboard' : 'Login'}</a>
                            </li>
                            {loginstatus != "true" &&
                              <li>
                                <a href="https://myaccount.wardsofkent.co.uk/account/register/" target="_blank">Register</a>
                              </li>
                            }
                             {loginstatus == "true" ?
                              <li>
                                <a href="https://myaccount.wardsofkent.co.uk/Property" target="_blank">Saved Properties</a>
                              </li>
                              :
                              <li>
                                <a href="/saved-properties/" target="_blank">Saved Properties</a>
                              </li>
                            }
                            <li>
                              <a href="https://thehub.arunestates.co.uk/wardsofkent/Login/" target="_blank">Tenant Login</a>
                            </li>
                            <li>
                              <a href="https://thehub.arunestates.co.uk/wardsofkent/Login/" target="_blank">Landlord Login</a>
                            </li>
                          </ul>
                        </div>
                        {/* </a> */}
                      </button>
                    </div>
                    <div className="menu-wrap d-lg-none">
                      <button className="hamburger" onClick={showMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>

                    </div>

                  </div>

                </div>
                {/* Navigation */}
                <div
                  className={
                    show
                      ? " navigation-wrap active justify-content-center"
                      : " navigation-wrap justify-content-center"
                  }
                >
                  <div className="navigation-title">
                    <span>menu</span>
                    <a href="javascript:void(0)" className="close-btn" onClick={closeMenu}>
                      <span>close</span> <i className="icon-close"></i>
                    </a>
                  </div>

                  <Nav>
                    <div className="navigation">
                      <div className="menu-accordian">
                        <ul>
                          {state.map((item, i) => (
                            <MenuData
                              key={i}
                              index={i}
                              handlerOpenMenu={handlerOpenMenu}
                              handlerOpenSubMenu={handlerOpenSubMenu}
                              item={item}
                            />
                          ))}
                        </ul>
                        <div className="second-nav">
                          <ul>
                            {/* <li className="card-header">
                            <Link to="#">Valuations</Link>
                          </li> */}
                            <li className="card-header">
                              <a href={process.env.GATSBY_CAREER_SITE_URL}>Careers</a>
                            </li>
                            <li className="card-header">
                              <Link to="/about-wards-of-kent/">About</Link>
                            </li>
                            <li className="card-header">
                              <a href="https://myaccount.wardsofkent.co.uk/" target="_blank">{loginstatus == "true" ? 'Dashboard' : 'Login'}</a>
                            </li>

                            {loginstatus != "true" &&
                              <li className="card-header">
                                <a href="https://myaccount.wardsofkent.co.uk/account/register/" target="_blank">Register</a>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                      <Link to="/sell-your-property/property-valuation" className="btn btn-secondary search-btn val-cta mobile-head-top-insta-cta">Instant Valuation<i className="icon-arrow head"></i></Link>
                      <MegaMenu />
                    </div>

                  </Nav>

                </div>
              </Nav>
            </Col>
          </Row>
        </Container>

        {/* <div className="navigation-block">
          <nav className="nav-menu">
            <ul>
              <li>
                <Link to="#">Property Search</Link>
              </li>
              <li>
                <Link to="#">Valuations</Link>
              </li>
              <li>
                <Link to="#">Services</Link>
              </li>
              <li>
                <Link to="#">Insights</Link>
              </li>
              <li>
                <Link to="#">About</Link>
              </li>
              <li>
                <Link to="#">Contact</Link>
              </li>
            </ul>
          </nav>
        </div> */}
      </header>
    </>
  );
};

export default Header;
