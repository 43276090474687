import axios from 'axios';
//import AWS from 'aws-sdk';
import {get, isString} from 'lodash';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
//require('aws-sdk/lib/maintenance_mode_message').suppress = true;
const baseURL = `${process.env.GATSBY_STRAPI_FORM_URL}`;
const token = process.env.GATSBY_STRAPI_FORM_TOKEN;
const s3 = new S3Client({
  region:process.env.GATSBY_AWS_REGION,
  credentials: {
    accessKeyId: process.env.GATSBY_AWS_ACCESSKEYID,
    secretAccessKey: process.env.GATSBY_AWS_SECRETACCESSKEY
  }
});

const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data"
  },
});


export const postFileData = async (data) => {

  const res = await authInstance.post('upload', data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })
  return res;

}

export const getFormData = async (formId) => { 
  const res = await authInstance.get(`/stb-forms/forms/${formId}`)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })
  return res;

}

export const updateFormData = async (data) => { 
  const res = await authInstance.put(`/stb-forms/forms/${data.id}`, data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })
  return res;

}

export const postFormData = async (data) => {
  const res = await authInstance.post('/stb-forms/forms', data)
  .then((res) => {
    // Success
    if (res.statusText === 'OK') {
      return {
        success: true,
        ...res.data,
      }
    }
    return { success: false }
  })
  .catch((error) => {
    // Failed
    if (error.response) {
      return {
        success: false,
        message: error.response.data,
      }
    } else {
      // Service error
    }
  })
  
  try{
  let formDatavalues= {};
  for (const pair of data.entries()) {
    if(isString(pair[1])){
      formDatavalues=JSON.parse(pair[1]);
    }
  }
  let email=formDatavalues.email || 'email';
  var current_date = new Date().toJSON().slice(0, -1);
  if(!res || get(res,'success')===false){
      let errorMessage=get(res,'message',"Strapi responsed to fail");
      const params =  new PutObjectCommand({

        Bucket: process.env.GATSBY_AWS_BUCKET, 
        Key: `${process.env.GATSBY_AWS_STBFORMSLOGPATH}_${email}${current_date.replaceAll(/[T:Z.]/g,'-')}.log`, 
        Body: JSON.stringify({fromdata:formDatavalues,error:errorMessage},null, 2), 
      });
      s3.send(params, function(s3Err, data) {
        if (s3Err) throw s3Err
      });
  }
  }catch(e){
    console.log(91,e);
  }

return res;

}